import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dao_icon = _resolveComponent("dao-icon")!
  const _component_dao_tooltip = _resolveComponent("dao-tooltip")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isA ? 'a' : 'button'), {
    class: _normalizeClass(["ghippo-header-button", {'ghippo-header-button-icon':_ctx.type === 'icon'}]),
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitClick($event)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_dao_tooltip, {
        disabled: !_ctx.tooltipContent,
        content: _ctx.tooltipContent,
        offset: "12"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createVNode(_component_dao_icon, {
              "use-font": "",
              name: _ctx.name
            }, null, 8, ["name"])
          ])
        ]),
        _: 3
      }, 8, ["disabled", "content"])
    ]),
    _: 3
  }, 8, ["class", "disabled"]))
}