import { Login } from '@daocloud-proto/ghippo/v1alpha1/login.pb';
import { linkToLogin } from '@/utils/login';
import { TOKEN, USERNAME } from '@/utils/constant';
import { USER_STORAGE } from '@dao-style/extend';
import { simpleErrorNoty } from '@/utils/noty';
import { i18n } from '@/plugins/vue-i18n';

export default async function logout(isManual = false) {
  let externalLogoutUrl = '';

  try {
    const res = await Login.OIDCLogout({});

    externalLogoutUrl = res.externalLogoutUrl ?? '';
  } catch (e) {
    simpleErrorNoty(e, {
      customTitle: i18n.t('utils.logout.failed'),
    });
  }
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(USERNAME);
  localStorage.removeItem(USER_STORAGE);

  if (!externalLogoutUrl) {
    linkToLogin(isManual);
  } else {
    window.location.href = externalLogoutUrl;
  }
}
