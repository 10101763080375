/* eslint-disable max-len */
import QueryString from 'qs';
import { type Login } from '@daocloud-proto/ghippo/v1alpha1/login.pb';
import hasLogged from '@/utils/hasLogged';
import pathJoin from '@/utils/pathJoin';
import getUrlBase from '@/utils/getUrlBase';
import { TOKEN, REFRESH_TOKEN, USERNAME } from './constant';

/**
1. token 是否存在？
   1. 存在
      2. 继续加载应用
         1. 接口请求
            1. 正常返回
               1. 结束
            2. 失效或不合法
               1. 清除 token
                  1. 进入步骤 1 （检查 token 是否存在）
   2. 不存在
      1. 检查当前的 location href 是否包含 login 需要的参数 （ code、state、session_state ）
         1. 是这种 url
            1. 取出 code 等，调用 login 的 post 接口
               1. 成功返回
                  1. 终止 login 流程
               2. 报错
                  1. 清除 url 上的 login 参数，重新 redirect 到当前 url（已移除 code 等） 上。
                  2. 进入 1（检查 token 是否存在）
         2. 不是这种 url
            1. 获取 login 的 get 接口，带上  callback_url （当前的location.href）然后 redirect 到接口地址
            2. 此时进入 keycloak 提供的 login 页面
            3. 输入账号密码后回到之前的 callback_url 中, 此时 callback_url 上已由 keycloak 拼接了 code 等信息，跳到初时判断 1（检查 token 是否存在）
 */

interface LoginRedirectParams {
  state?: string;
  code?: string;
  session_state?: string;
}

function checkIsLoginUrl(loginRedirectParam: LoginRedirectParams) {
  return loginRedirectParam.state && loginRedirectParam.code && loginRedirectParam.session_state;
}

export const ERROR_MESSAGE_NO_ID_TOKEN = 'No id token in response data.';

const ERROR_MAP: Record<string, string> = {
  [ERROR_MESSAGE_NO_ID_TOKEN]: ERROR_MESSAGE_NO_ID_TOKEN,
};

export function removeLoginParam(url: string) {
  const urlObj = new URL(url);
  const urlSearchObj = QueryString.parse(urlObj.search.slice(1));

  ['state', 'session_state', 'code', 'callback_url'].forEach((key) => {
    if (urlSearchObj[key]) {
      delete urlSearchObj[key];
    }
  });
  urlObj.search = QueryString.stringify(urlSearchObj);

  return urlObj.toString();
}

export function linkToLogin(isManual = false) {
  const { location } = window;
  let callbackUrlStr = `${location.origin}/`;

  if (!isManual) {
    callbackUrlStr = encodeURIComponent(removeLoginParam(location.href));
  }

  location.href = pathJoin(getUrlBase(), `/apis/ghippo.io/v1alpha1/login?callback_url=${callbackUrlStr}`);
}

export async function anakinLogin(LoginService: typeof Login.OIDCLogin) {
  const { location } = window;
  const token = localStorage.getItem(TOKEN);

  if (!token) {
    const preventError = new Error('Prevent render page after redirect to login page.');
    const searchObj = QueryString.parse(location.search.slice(1));

    const isLoginUrl = checkIsLoginUrl(searchObj);

    if (isLoginUrl) {
      try {
        // searchObj 是直接url获取所以会出现session_state，后端做了兼容处理_和驼峰都可以
        const loginInfo = await LoginService({
          ...searchObj,
          callbackUrl: removeLoginParam(location.href),
        });

        if (loginInfo.idToken && loginInfo.refreshToken && loginInfo.username) {
          localStorage.setItem(TOKEN, loginInfo.idToken);
          localStorage.setItem(REFRESH_TOKEN, loginInfo.refreshToken);
          // 当浏览器关闭需要登出时，通过hasLogged区分是用户新开页（session中无数据）还是刷新页
          hasLogged.value = true;
          localStorage.setItem(USERNAME, loginInfo.username);
          window.history.replaceState(null, '', removeLoginParam(location.href));

          return true;
        }
        throw new Error(ERROR_MESSAGE_NO_ID_TOKEN);
      } catch (e: unknown) {
        location.href = removeLoginParam(location.href);
        if (ERROR_MAP[(e as Error).message]) {
          throw e;
        }

        throw preventError;
      }
    } else {
      linkToLogin();
      throw preventError;
    }
  } else {
    return false;
  }
}
