import { GProducts } from '@daocloud-proto/ghippo/v1alpha1/gproduct.pb';
import { useGlobalStore } from '@/stores';
import { simpleErrorNoty } from '@/utils/noty';
import { i18n } from '@/plugins/vue-i18n';

export default async function getProductListInfo() {
  try {
    const store = useGlobalStore();
    const { data = [] } = await GProducts.ListGProducts({});

    store.setProductList(data);

    return data;
  } catch (e) {
    simpleErrorNoty(e, {
      name: i18n.t('utils.productListInfo.list'),
    });

    return [];
  }
}
