
import {
  computed, defineComponent, PropType, ref,
} from 'vue';
import { type ProductMenu } from '@daocloud-proto/ghippo/v1alpha1/productnav.pb';

export default defineComponent({
  name: 'AnakinNavItem',
  props: {
    nav: {
      type: Object as PropType<ProductMenu>,
      default: () => ({}),
    },
  },
  emits: ['focus-sub-nav', 'blur-sub-nav', 'hidden-sidebar'],
  setup(props, { emit }) {
    const hasSubNav = computed(() => !!props.nav?.menus?.length);

    const subNavShow = ref(false);
    const showSubNav = () => {
      if (hasSubNav.value) {
        subNavShow.value = true;
      }
    };
    const hiddenSubNav = () => {
      if (subNavShow.value) {
        subNavShow.value = false;
      }
    };

    const focusSubNav = () => {
      emit('focus-sub-nav');
    };
    const blurSubNav = () => {
      emit('blur-sub-nav');
    };

    const hiddenSidebar = () => {
      emit('hidden-sidebar');
    };

    const isBlank = (target: string | undefined) => target?.includes('blank');

    const getTarget = (target: string | undefined) => (isBlank(target) ? '_blank' : '_self');

    const { href: defaultSvgUrl } = new URL('@/assets/default-config.svg', import.meta.url);

    function getSrc<T>(url:T) {
      return url || defaultSvgUrl;
    }

    return {
      hasSubNav,
      subNavShow,
      showSubNav,
      hiddenSubNav,
      focusSubNav,
      blurSubNav,
      hiddenSidebar,
      getTarget,
      getSrc,
    };
  },
});
