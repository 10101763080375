import type { App } from 'vue';

import daoStyle from '@dao-style/core';
import '@dao-style/core/dist/style.css';

import {
  createNoty,
  setNotyDefault,
  installer,
  DaoHistoryLink,
  createGlobalLoading,
  vLoading,
  DaoConfirmDialogFooter,
  DaoFormItemValidate,
  registerAllValidations,
  type VueI18nLike,
} from '@dao-style/extend';

const components = {
  DaoHistoryLink,
  DaoConfirmDialogFooter,
  DaoFormItemValidate,
};

export const noty = createNoty();

setNotyDefault({
  showClose: true,
});

export const useGlobalLoading = createGlobalLoading();

const installDaoStyle = (Vue: App, vueI18nLike: VueI18nLike): void => {
  Vue.use(daoStyle);
  Vue.use(installer(components));
  noty.install(Vue);
  registerAllValidations(vueI18nLike);
  useGlobalLoading.install(Vue);
  Vue.directive('loading', vLoading);
};

export default installDaoStyle;
