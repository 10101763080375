import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  tabindex: "0",
  class: "ghippo-nav-item-text"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dao_history_link = _resolveComponent("dao-history-link")!
  const _component_dao_icon = _resolveComponent("dao-icon")!
  const _component_dao_popover = _resolveComponent("dao-popover")!

  return (_openBlock(), _createBlock(_component_dao_popover, {
    modelValue: _ctx.subNavShow,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.subNavShow) = $event)),
    placement: "right-start",
    trigger: "manual",
    "append-to": false,
    "visible-arrow": false,
    delay: 0,
    disabled: !_ctx.hasSubNav,
    "popper-class": "ghippo-header-sub-nav-main",
    class: "ghippo-nav-body",
    "popper-options": {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0,-2],
          },
        },
      ],
    }
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", {
        class: "ghippo-header-sub-nav-container",
        onMouseenter: _cache[4] || (_cache[4] = ($event: any) => {_ctx.showSubNav();_ctx.focusSubNav();}),
        onFocusin: _cache[5] || (_cache[5] = ($event: any) => {_ctx.showSubNav();_ctx.focusSubNav();}),
        onMouseleave: _cache[6] || (_cache[6] = ($event: any) => {_ctx.hiddenSubNav();_ctx.blurSubNav();}),
        onFocusout: _cache[7] || (_cache[7] = ($event: any) => {_ctx.hiddenSubNav();_ctx.blurSubNav();})
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nav.menus, (sub) => {
          return (_openBlock(), _createBlock(_component_dao_history_link, {
            key: (sub?.name || '') + sub?.url,
            href: sub.url,
            target: _ctx.getTarget(sub.target),
            class: "ghippo-header-sub-nav-item",
            onPushState: _ctx.hiddenSidebar
          }, {
            default: _withCtx(() => [
              (sub.iconUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: sub.iconUrl,
                    class: "ghippo-sub-item-icon",
                    alt: ""
                  }, null, 8, _hoisted_3))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(sub.name), 1)
            ]),
            _: 2
          }, 1032, ["href", "target", "onPushState"]))
        }), 128))
      ], 32)
    ]),
    default: _withCtx(() => [
      _createElementVNode("nav", {
        class: "ghippo-nav-item",
        onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showSubNav && _ctx.showSubNav(...args))),
        onFocusin: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showSubNav && _ctx.showSubNav(...args))),
        onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.hiddenSubNav && _ctx.hiddenSubNav(...args))),
        onFocusout: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.hiddenSubNav && _ctx.hiddenSubNav(...args)))
      }, [
        _createElementVNode("img", {
          alt: "",
          src: _ctx.getSrc(_ctx.nav.iconUrl),
          class: "ghippo-nav-item-icon"
        }, null, 8, _hoisted_1),
        (_ctx.nav.url === '')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.nav.name), 1))
          : (_openBlock(), _createBlock(_component_dao_history_link, {
              key: 1,
              href: _ctx.nav.url,
              target: _ctx.getTarget(_ctx.nav.target),
              class: "ghippo-nav-item-text",
              onPushState: _ctx.hiddenSidebar
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.nav.name), 1)
              ]),
              _: 1
            }, 8, ["href", "target", "onPushState"])),
        (_ctx.hasSubNav)
          ? (_openBlock(), _createBlock(_component_dao_icon, {
              key: 2,
              class: "ghippo-nav-item-icon--more",
              name: _ctx.nav.url === '' ? 'icon-dropdown-line' : 'icon-dropdown-line',
              "use-font": ""
            }, null, 8, ["name"]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["modelValue", "disabled"]))
}