
import {
  defineComponent, type PropType, ref, inject,
} from 'vue';
import { type ProductNavCategory } from '@daocloud-proto/ghippo/v1alpha1/productnav.pb';
import AnakinHeaderButton from '@/components/AnakinHeaderButton.vue';
import AnakinNavItem from '@/components/AnakinNavItem.vue';
import ClickOutside from '@/directives/clickoutside';
import { IS_FIRST_LOGIN } from '@/utils/constant';

export default defineComponent({
  name: 'AnakinNav',
  components: {
    AnakinHeaderButton,
    AnakinNavItem,
  },
  directives: {
    ClickOutside,
  },
  props: {
    navs: {
      type: Array as PropType<ProductNavCategory[]>,
      default: () => [],
    },
  },
  setup() {
    const isFirst = inject(IS_FIRST_LOGIN, false);

    const sidebarShow = ref(isFirst);
    const showSidebar = () => {
      sidebarShow.value = true;
    };
    const hiddenSidebar = () => {
      sidebarShow.value = false;
    };
    const toggleSidebar = () => {
      if (sidebarShow.value) {
        hiddenSidebar();
      } else {
        showSidebar();
      }
    };

    const subNavFocus = ref(false);
    const focusSubNav = () => {
      subNavFocus.value = true;
    };
    const blurSubNav = () => {
      subNavFocus.value = false;
    };

    return {
      sidebarShow,
      hiddenSidebar,
      toggleSidebar,

      subNavFocus,
      focusSubNav,
      blurSubNav,
    };
  },
});
