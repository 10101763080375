import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "ghippo-header-sidebar-main" }
const _hoisted_2 = {
  key: 0,
  class: "ghippo-nav-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anakin_header_button = _resolveComponent("anakin-header-button")!
  const _component_anakin_nav_item = _resolveComponent("anakin-nav-item")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_anakin_header_button, {
      class: "ghippo-header-aside-button",
      name: "icon-icons-view",
      type: "icon",
      onKeyup: _withKeys(_ctx.hiddenSidebar, ["esc"]),
      onClick: _ctx.toggleSidebar
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: _normalizeClass(["ghippo-header-aside-icon", {'ghippo-header-aside-icon--active':_ctx.sidebarShow}])
        }, null, 2)
      ]),
      _: 1
    }, 8, ["onKeyup", "onClick"]),
    _createVNode(_Transition, { name: "slide-in-left" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("aside", {
          class: _normalizeClass(["ghippo-header-nav-main", {'ghippo-header-nav-main--no-scrollbar':_ctx.subNavFocus}])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navs, (navGroup, index) => {
            return (_openBlock(), _createElementBlock("nav", {
              key: (navGroup.name || '') + index,
              class: "ghippo-nav-group"
            }, [
              (navGroup.name !== '')
                ? (_openBlock(), _createElementBlock("header", _hoisted_2, _toDisplayString(navGroup.name), 1))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navGroup.menus, (nav) => {
                return (_openBlock(), _createBlock(_component_anakin_nav_item, {
                  key: (nav?.name || '') + nav?.url,
                  nav: nav,
                  class: "ghippo-nav-body",
                  onKeyup: _withKeys(_ctx.hiddenSidebar, ["esc"]),
                  onHiddenSidebar: _ctx.hiddenSidebar,
                  onFocusSubNav: _ctx.focusSubNav,
                  onBlurSubNav: _ctx.blurSubNav
                }, null, 8, ["nav", "onKeyup", "onHiddenSidebar", "onFocusSubNav", "onBlurSubNav"]))
              }), 128))
            ]))
          }), 128))
        ], 2), [
          [_vShow, _ctx.sidebarShow]
        ])
      ]),
      _: 1
    })
  ])), [
    [_directive_click_outside, _ctx.hiddenSidebar]
  ])
}