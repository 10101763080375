
import { defineComponent, type PropType } from 'vue';

const buttonType = ['button', 'icon', 'link'] as const;

type ButtonType = typeof buttonType[number];

export default defineComponent({
  name: 'AnakinHeaderButton',
  props: {
    name: {
      type: String,
      default: '',
    },
    tooltipContent: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<ButtonType>,
      default: 'button',
      validator: (value: ButtonType) => buttonType.includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    isA() {
      return this.type === 'link';
    },
  },
  methods: {
    emitClick(e: MouseEvent) {
      if (!this.disabled) {
        this.$emit('click', e);
      }
    },
  },
});
