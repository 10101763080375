import { Account } from '@daocloud-proto/ghippo/v1alpha1/currentuser.pb';
import { useGlobalStore } from '@/stores';
import { simpleErrorNoty, type grpcError } from '@/utils/noty';
import { i18n } from '@/plugins/vue-i18n';
import logout from './logout';

export default async function getUserInfo() {
  const globalStore = useGlobalStore();

  try {
    const {
      username,
      email,
      locale,
    } = await Account.GetUser({});

    if (username) {
      globalStore.setUsername(username);
    } else {
      console.error('username:', username);
    }
    globalStore.setEmail(email || '');
    await globalStore.setLocale(locale || '');

    return {
      username,
      email,
      locale,
    };
  } catch (e) {
    await globalStore.setLocale(window.navigator.language);
    simpleErrorNoty(e, {
      name: i18n.t('utils.userInfo.info'),
    });
    // 与后端约定当用户不存在时，code为5
    if ((e as grpcError).code === 5) {
      logout();
    }

    return {};
  }
}
