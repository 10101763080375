import jwtDecode, { JwtPayload } from 'jwt-decode';
import { TOKEN, TOKEN_LAST_UPDATE_TIME } from '@/utils/constant';
import throttle from 'lodash/throttle';
import refreshTokenFunc from '@/utils/refreshToken';

const checkTime = 60 * 10 * 1000; // 10 min

let lastOperationTime = Date.now();

async function checkSessionTimeout() {
  const now = Date.now();
  const lastUpdateTime = localStorage.getItem(TOKEN_LAST_UPDATE_TIME);

  // 距离上次更新时间不足10 min 时，不更新
  if (lastUpdateTime && now - Number(lastUpdateTime) < checkTime) {
    return;
  }

  if (now - lastOperationTime < checkTime) {
    await refreshTokenFunc();
  }
}

let loopTimer: number | undefined;

function checkSessionTimeoutLoop() {
  return setTimeout(() => {
    checkSessionTimeout();
    loopTimer = checkSessionTimeoutLoop();
  }, checkTime);
}

export function startCheckSessionTimeout() {
  if (loopTimer !== undefined) {
    clearTimeout(loopTimer);
  } else {
    const throttleUpdateLastOperationTimeFn = throttle(() => {
      lastOperationTime = Date.now();
    }, 5 * 1e3);

    document.addEventListener('mousemove', throttleUpdateLastOperationTimeFn);
    document.addEventListener('keydown', throttleUpdateLastOperationTimeFn);
    document.addEventListener('visibilitychange', async () => {
      if (!document.hidden) {
        lastOperationTime = Date.now();
        const jwt = jwtDecode<JwtPayload>(localStorage.getItem(TOKEN) || '');

        const { exp } = jwt;

        if (!exp) {
          console.error('JWT exp invalid. jwt info: ', jwt);

          return;
        }

        // 切换标签页后，token有效时间小于10min，调用refreshToken。
        if (exp * 1e3 - lastOperationTime < checkTime) {
          await refreshTokenFunc();
        }
      }
    });
  }
  loopTimer = checkSessionTimeoutLoop();
}
