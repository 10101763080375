import { Login } from '@daocloud-proto/ghippo/v1alpha1/login.pb';
import { REFRESH_TOKEN, TOKEN_LAST_UPDATE_TIME } from '@/utils/constant';
import { useGlobalStore } from '@/stores';
import { simpleErrorNoty } from '@/utils/noty';

export default async function refreshTokenFunc() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);

  if (!refreshToken) {
    return;
  }
  try {
    const {
      idToken: newIdToken,
      refreshToken: newRefreshToken,
    } = await Login.RefreshToken({
      refreshToken,
    });

    if (newIdToken && newRefreshToken) {
      const store = useGlobalStore();

      store.setIdToken(newIdToken);

      localStorage.setItem(REFRESH_TOKEN, newRefreshToken);
      localStorage.setItem(TOKEN_LAST_UPDATE_TIME, Date.now().toString());
    }
  } catch (e) {
    simpleErrorNoty(e, {
      name: 'token',
    });
  }
}
